import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import EmailSignup from "../components/EmailSignup"

export default function about(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <main className="">
        <h1 className="text-center font-bold text-3xl">Subscribe</h1>
        <h3 className="text-center">Weekly Tips on How to Learn Languages</h3>
        <ul className="mt-5 ml-20 ">
          <li className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 mr-2"
            >
              {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
              <path
                className="fill-current text-indigo-800"
                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
              />
            </svg>
            <span className="text-indigo-800 font-bold mr-1">Actionable </span>{" "}
            tips and tricks
          </li>
          <li className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 mr-2"
            >
              {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
              <path
                className="fill-current text-indigo-800"
                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
              />
            </svg>
            <span className="text-indigo-800 font-bold mr-1">
              Neuroscience based
            </span>{" "}
            techniques and strategies
          </li>
          <li className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 mr-2"
            >
              {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
              <path
                className="fill-current text-indigo-800"
                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
              />
            </svg>
            Updates on my progress
          </li>
          <li className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 mr-2"
            >
              {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
              <path
                className="fill-current text-indigo-800"
                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
              />
            </svg>
            Vocabulary lists and study guides
          </li>
          <li className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-8 w-8 mr-2"
            >
              {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
              <path
                className="fill-current text-indigo-800"
                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
              />
            </svg>
            Easily unsubscribe anytime
          </li>
        </ul>
        {/* <form
          style={{ maxWidth: `400px` }}
          className="mt-5 flex flex-col mx-auto "
        >
          <input
            style={{ minWidth: `300px` }}
            className="bg-gray-200 placeholder-gray-600 rounded p-2 px-3"
            type="text"
            placeholder="example@example.com"
          ></input>
          <button className="mt-3 p-2 px-8 rounded shadow-xl bg-indigo-800 text-white hover:bg-indigo-600">
            I want to learn!
          </button>
        </form> */}
        <div
          className="mt-4 flex justify-center mx-auto"
          style={{ maxWidth: `400px` }}
        >
          <EmailSignup></EmailSignup>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
